import React from 'react';
import { useEffect } from 'react';
import { checkUserExists } from "../../api/user"


const EmailForm = ({usersubmitFunc,loadScreen,showRegisteration}) => {
	const emailEnv = process.env.REACT_APP_EMAIL;

    const [email , setEmail ] = React.useState(emailEnv)
	const [placeholder , setPlacehodler ] = React.useState('COMPANY OR BUSINESS EMAIL ADDRESS')
	const [emailError , setEmailError ] = React.useState('')
	// const [disableSbtBtn , setDisableStatus ] = React.useState(true)
	const [redirectUrl , setRedirectUrl ] = React.useState('')
    const myRefname= React.useRef(null);
	useEffect(() => {

		const queryParams = new URLSearchParams(window.location.search)
		const email = queryParams.get("email")
		if(email){

			setRedirectUrl(email)
			setEmail(email)
		}

	},[])

	useEffect(() => {

		if(redirectUrl) myRefname.current.click();
        

	},[redirectUrl])

    const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  );
	};

    const submitForm = (e) => {



		// var element = document.querySelector("#root");

		// element.requestFullscreen()
		// .then(function() {
		// 	// element has entered fullscreen mode successfully
		// })
		// .catch(function(error) {
		// 	// element could not enter fullscreen mode
		// 	// error message
		// 	console.log(error.message);
		// });

		//if(disableSbtBtn) return;


		if(email === "") {
			setEmailError("This email field is required.");
			return false;
		}

		if (validateEmail(email)) {


			loadScreen(true)
			let userExist = checkUserExists(email)


			userExist.then(
				function(value) {

					if(value.data.response === "success") {

						window.sessionStorage.clear();
						let user = value.data.data.id
						let email = value.data.data.email

						sessionStorage.setItem("user_id", user);
						sessionStorage.setItem("user_email", email);

						usersubmitFunc();

					}

					
					if(value.data.response === "error") {

						setEmailError("Sorry, User not exist");
						if(process.env.REACT_APP_WELCOME_SCREEN === "show") {

							showRegisteration(email)
						}
                        //
					}
					loadScreen(false)

				},
				function(error) {


					if(error.response === "error") {

						setEmailError(error.data);

					}else{

						setEmailError("Network Error");

					}
				}
			)


		} else {
			setEmailError("Email is invalid.");
		}
		return false
	}
    return(
        <>
            <div className='title'>Welcome <span>to</span></div>
            <img className="tt_logo_2023" src="images/Logo_TT_2023VirtualExp.png" alt="" />
            <input type="text" 
                className="email-input" id="exampleFormControlInput1" 
                placeholder={placeholder}
                value={email}
                onChange={ (e) => {setEmail(e.target.value) }}
            />
            <div className="is-danger">{emailError}</div>
        
            <div className="btn btn-login" ref={myRefname} onClick={submitForm} >Submit</div>
            <div className="label label-help">For Questions, Feedback and Technical Issues contact us at: <a href="">Help Desk</a></div> 
            <div className='label label-note'>Internet Explorer is not supported</div>  
        
        </>
     
    )
}

export default EmailForm
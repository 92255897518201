import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { Suspense } from "react";
import { useEffect } from "react";
import LoaderComponent from "./components/Loader";
import Welcome from "./components/Welcome";

const MainComponent = React.lazy(() => import('./components/Main'))

function App() {

  const [userSubmited , setUserSubmited ] = React.useState(false)
  const [loading , setLoading ] = React.useState(false)
  const [showMainComponent , setShowMainComponent ] = React.useState(false)

  useEffect(() => {

    if(userSubmited) {
      setShowMainComponent(true)
    }

  },[userSubmited])

  const handleUserSubmit =  () => {

      setUserSubmited(true);
  }

  const handleLoadScreen = (status) => {
    setLoading(status) 
  }

  return (
    <>
      { !showMainComponent && <LoaderComponent isLoading={loading} /> }
      <Welcome userSubmited={userSubmited} usersubmitFunc = {handleUserSubmit} loadScreen={handleLoadScreen}/>
      <Suspense fallback={<div></div>}>
        <MainComponent userSubmited={showMainComponent}/>
      </Suspense>
    </>

  );
}

export default App;

import axios from "axios";

// // let baseUrl = 'https://2023virtualexperience.thetunagroup.com';
// let baseUrl = 'https://2023virtualexperiencetest.thetunagroup.com';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

function isIOS() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
    } else {
        return navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform);
    }
}

function isIpadOS() {
    return navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform);
}

function getDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) { return "windows"; }
    if (/android/i.test(userAgent)) { return "android"; }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { return "ios"; }
    if(isIOS()) {
        return "ios";
    }
    if(isIpadOS()) {
        return "ios";
    }
    return undefined;
}



const device = getDevice()
let device_type = null
if (device === undefined) {


    device_type = "Web";
}else{

    device_type = "Mobile";

}



export const checkUserExists = async (email_id) => {

    let url = '/api/user/exists'
    const reqData = {
        email : email_id,
        device_type : device_type
    }
    const response =    await axios.post(baseUrl+url,reqData);
    return response;

}

export const fetchUserDetails = async (user_id) => {

    let url = '/api/user/details'
    const reqData = {
        user_id : user_id,
    }
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}
export const fetchCompanyTypes = async () => {

    let url = '/api/company/types/get'
    const response =    await axios.get(baseUrl+url);
    return response;
}


export const fetchJobRoles = async () => {

    let url = '/api/job/roles/get'
    const response =    await axios.get(baseUrl+url);
    return response;
}

export const fetchState = async (country) => {

    let url = '/api/states/get?country_id='+country
    const reqData = {
        country_id : country,
    }
    const response =    await axios.post(baseUrl+url,[]);
    return response;
}

export const createUser = async (requestData) => {
    let url = '/api/register/user'
    requestData.device_type = device_type
    const response =    await axios.post(baseUrl+url,requestData);
    return response;
}


export const addFavouritePdf = async (reqData) => {

    let url = '/api/wishlist/post'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}

export const getFavouritePdf = async (reqData) => {

    let url = '/api/wishlist/get'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}

export const removeFavouritePdf = async (reqData) => {

    let url = '/api/wishlist/remove'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}


export const fetchVideoList = async (reqData) => {

    let url = '/api/video/status/list'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}

let startTime = null, endTime = null;

function end() {
    endTime = new Date();
    let timeDiff = endTime - startTime; //in ms
    // strip the ms
    timeDiff /= 1000;
  
    // get seconds 
    let seconds = Math.round(timeDiff);

    return seconds
}



//Analytics Api Call Indie Fypon and Thermatru tools and support two pillars
export const callAnalyticsAPIOther = async (reqData) => {
    let session_id = sessionStorage.getItem("session_id");
    if(session_id) {
        reqData.session_id = session_id
    }
    reqData.device_type = device_type
    let url = '/api/analytics/post'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}


const notSendApiAreas = ['thermatru_tools_support','thermatru_key_success','tools_support_fypon']
export const callAnalyticsAPI = async (reqData) => {

    if(reqData.resource_type === "area"){

        if(startTime === null) {
            startTime = new Date();
        }else{
            reqData.spent_time = end()
            startTime = new Date();
                  
        }

        if(notSendApiAreas.includes(reqData.prev_area)){
            return;
        }
    
    }

    let session_id = sessionStorage.getItem("session_id");
    if(session_id) {
        reqData.session_id = session_id
    }
    reqData.device_type = device_type

    let url = '/api/analytics/post'
    const response =    await axios.post(baseUrl+url,reqData);


    return response;
}


export const createSmeParticipant = async (requestData) => {
    let url = '/api/participiant/add'
    const response =    await axios.post(baseUrl+url,requestData);
    return response;
}

export const fetchSmeParticipants = async (reqData) => {

    let url = '/api/participiant/get'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}


export const removeSmeParticipant = async (reqData) => {

    let url = '/api/participiant/delete'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}


export const shareUserFavourites = async (reqData) => {

    let url = '/api/email/wishlist'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}

export const smeSessionStart = async (reqData) => {

    let url = '/api/smesession/add'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}


export const smeSessionEnd = async (reqData) => {

    let url = '/api/smesession/end'
    const response =    await axios.post(baseUrl+url,reqData);
    return response;
}

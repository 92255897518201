const RegisterationMessage = () => {

    return(

        <div className="login_box">
            <form className="login_box_inner">
                <div className="text-center">
                <img src="images/logo.png" className="form_logo_pop" alt="" />
                <h3 className="mb-0"><span className="experience_col">2023</span>  Virtual <span className="experience_col">Experience</span></h3>
                <h4 className="mt-4">Thank you, you have been registered! Join us on February 14th, 2023 to explore our Virtual Experience.</h4>
                </div>
            </form>
        </div>
    )
}

export default RegisterationMessage;
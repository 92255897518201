import React from "react";

class LoaderComponent extends React.PureComponent{
    constructor(props) {
        super(props)

        this.state = {
            loading : props.isLoading,
            // loadingPercentage : props.loadingCount
        }
    }
    componentDidUpdate() {
        if(this.state.loading !== this.props.isLoading) {
            this.setState({loading : this.props.isLoading  })
        }
    }

    render() {
        return(
            <div className={`back-board flex-part loading  ${this.state.loading?'active show':''} `}>
			    <div className='loading-circle'></div>
		    </div>
        )
    }
}


export default LoaderComponent
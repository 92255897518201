import React, {  useState } from 'react';
import '../../assets/css/pre_registration_v1.css';
import '../../assets/css/pre_registration_v2.css';

import EmailForm from './EmailForm';
import RegisterationForm from './RegisterationForm';
import RegisterationMessage from './RegisterationMessage';
import RegisterationPromotionForm from './RegisterationPrmotionForm';
import { createUser } from "../../api/user"
import { useEffect } from 'react';



const Welcome = ({usersubmitFunc,loadScreen,userSubmited}) => {
	
	const [currentForm , setCurrentForm ] = useState(1)
	const [formData , setFormData] = useState(null)
	const [userEmail , setUserEmail] = useState(null)
	const [wel_width , setWelWIdth] = useState(window.innerWidth)
	const [wel_height , setWelHeight] = useState(window.innerHeight)
	const [promotionalError , setpromotionalError] = useState(null)

	useEffect(() => {

		window.addEventListener('resize',setsize);

	},[])

	const setsize = () => {
		setWelWIdth( window.innerWidth)
		setWelHeight( window.innerHeight)
	}

	const showRegisteration = (email) => {

		setUserEmail(email)
		setCurrentForm(2)
	}

	const showRegisterationPromotion = (data) => {
		//store registeration data
		setFormData(data)
		setCurrentForm(3)
	}

	const showRegisterationMessgae = (data) => {



		let requestData = {...formData,...data}
		requestData.id = 0;
		requestData.email = userEmail;

		let user = createUser(requestData);
		user.then((value) => {

			if(value.data.response === "success") {

				window.sessionStorage.clear();
				let user = value.data.user.id
				sessionStorage.setItem("user_id", user);
				usersubmitFunc();
				// setCurrentForm(4)
			
			}

			if(value.data.response === "error") {

				setpromotionalError(value.data.message)
			}
		})
		//setCurrentForm(4)
	}
	

	return(

		<>
			<div className={`wrapper back-board  ${!userSubmited ? 'active': ''}`} style={{ width: wel_width, height: wel_height }}>

				<div className='login-wrapper'>
					<div className="login-inner">
						{
							currentForm  === 1 && <EmailForm usersubmitFunc={usersubmitFunc} loadScreen={loadScreen} showRegisteration={showRegisteration} /> 
						}
						{
							currentForm  === 2 && <RegisterationForm showRegisterationPromotion={showRegisterationPromotion}	/> 
						}
						
						{
							currentForm  === 3 && <RegisterationPromotionForm showRegisterationMessgae={showRegisterationMessgae} errormsg={promotionalError}/>
						}

						{
							currentForm === 4 && <RegisterationMessage />
						}
					</div>
				</div>
				<div className="canvas_div" style={{ width: wel_width, height: wel_height }}>
				<img src="images/home_view.png" alt="" className="w-100" onLoad={
					() => {
						loadScreen(false)
					}
				}/>
				</div>
			</div>
			
		</>
	)
}

export default Welcome